import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Coupon, CouponData } from "./types";

// Customizable Area Start
import moment from "moment/moment";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: any;
  data: CouponData[];
  selected?: Coupon;
  token: string;
  isVisible: boolean;
  isRefreshing: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
}

export default class CouponcodetableController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCouponApiCallId?: string;
  deleteApiCallId?: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      id: 0,
      data: mockData.data,
      token: "",
      isVisible: false,
      isRefreshing: false,
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: String, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCouponApiCallId != null &&
      this.getCouponApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse) {
        this.parseApiCatchErrorResponse(errorResponse);
      }

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          data: responseJson.data,
        });
      } else {
        this.showAlert(configJSON.errorTitle, configJSON.unexpectedError);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteApiCallId != null &&
      this.deleteApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({
        isVisible: false,
        isRefreshing: false,
        selected: undefined,
      });

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.getListRequest(this.state.token);
      } else {
        errorResponse && this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  // Customizable Area Start
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCouponApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeCouponGet
    );

    this.setState({ isRefreshing: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteRecord(value: number) {
    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint + "/" + `${value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteCouponAPiMethod
    );

    this.setState({ isRefreshing: true, selected: undefined });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToCodeGenerator = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Couponcodegenerator"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };

  setSelected(value?: Coupon) {
    this.setState({ selected: value });
  }

  closeDialog() {
    this.setSelected(undefined);
  }

  formatFromDateString(value: string) {
    return moment(value).format(configJSON.format);
  }

  // Customizable Area End
}
const mockData = {
  data: [
    {
      id: "7",
      type: "coupon_code",
      attributes: {
        id: 7,
        title: "CCG-31",
        description: "Coupon cod1e genrator 21",
        code: "Code231",
        discount_type: "flat",
        discount: "10.5",
        valid_from: "2020-09-12T16:07:58.664Z",
        valid_to: "2020-11-12T16:07:58.664Z",
        min_cart_value: "1.0",
        max_cart_value: "200.0",
        created_at: "2023-03-16T05:36:21.600Z",
        updated_at: "2023-03-16T05:36:21.600Z",
      },
    },
    {
      id: "8",
      type: "coupon_code",
      attributes: {
        id: 8,
        title: "CCG-31",
        description: "Coupon cod1e genrator 21",
        code: "Code213",
        discount_type: "flat",
        discount: "10.5",
        valid_from: "2020-09-12T16:07:58.664Z",
        valid_to: "2020-11-12T16:07:58.664Z",
        min_cart_value: "1.0",
        max_cart_value: "200.0",
        created_at: "2023-03-16T06:24:52.587Z",
        updated_at: "2023-03-16T06:24:52.587Z",
      },
    },
    {
      id: "11",
      type: "coupon_code",
      attributes: {
        id: 11,
        title: "CCG-1",
        description: "Coupon code genrator 1",
        code: "Code",
        discount_type: "percentage",
        discount: "10.5",
        valid_from: "2020-09-12T16:07:58.664Z",
        valid_to: "2020-11-12T16:07:58.664Z",
        min_cart_value: "1.0",
        max_cart_value: "200.0",
        created_at: "2023-03-16T17:06:13.125Z",
        updated_at: "2023-03-16T17:09:01.835Z",
      },
    },
    {
      id: "10",
      type: "coupon_code",
      attributes: {
        id: 10,
        title: "CCG-1",
        description: "Coupon code genrator 2",
        code: "code",
        discount_type: "percentage",
        discount: "10.5",
        valid_from: "2022-12-17T10:29:55.000Z",
        valid_to: "2022-12-17T10:29:55.000Z",
        min_cart_value: "1.0",
        max_cart_value: "200.0",
        created_at: "2023-03-16T17:04:12.851Z",
        updated_at: "2023-03-16T17:05:07.454Z",
      },
    },
  ],
};
