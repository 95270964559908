import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormGroup,
  MenuItem,
  TextField,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import CouponcodegeneratorController, {
  configJSON,
  Props,
} from "./CouponcodegeneratorController";

import "@mui/styles";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class Couponcodegenerator extends CouponcodegeneratorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <Box sx={styles.flexRow}>
              <Button
                data-test-id={configJSON.viewAllButtonTestId}
                variant="contained"
                onClick={() => this.navigateToCouponCodeTable()}
              >
                {configJSON.viewAll}
              </Button>
            </Box>
            {this.state.isRefreshing ? (
              <Box
                data-test-id={configJSON.progressTestId}
                width={"100%"}
                sx={{ ...styles.flexRow, ...styles.marginTop }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box width={"100%"}>
                <>
                  <FormGroup style={styles.formGroupContainer}>
                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.titleTestId}
                        type="text"
                        required
                        label={configJSON.titleLabel}
                        variant="standard"
                        defaultValue={this.state.title}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({ title: event.target.value });
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>

                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.discountTypeTestId}
                        select
                        required
                        label={configJSON.discountType}
                        value={this.state.discount_type}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({ discount_type: event.target.value });
                        }}
                      >
                        {this.state.discount_types.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.descriptionTestId}
                        type="text"
                        required
                        multiline
                        label={configJSON.description}
                        variant="standard"
                        defaultValue={this.state.description}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({ description: event.target.value });
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>

                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.codeTestId}
                        type="text"
                        required
                        label={configJSON.code}
                        variant="standard"
                        defaultValue={this.state.code}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({ code: event.target.value });
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>

                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.discountTestId}
                        type="number"
                        required
                        label={configJSON.discount}
                        variant="standard"
                        defaultValue={this.state.discount}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({ discount: event.target.value });
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>

                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.validFromTestId}
                        type="date"
                        required
                        label={configJSON.validFrom}
                        variant="standard"
                        placeholder={configJSON.selectDate}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          min: configJSON.minDate,
                          max: configJSON.maxDate,
                        }}
                        defaultValue={this.formatDate(
                          this.state.valid_from,
                          configJSON.formatWeb
                        )}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({
                            valid_from: this.toDateISOString(
                              event.target.value
                            ),
                          });
                        }}
                      />
                    </FormControl>
                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.validToTestId}
                        type="date"
                        required
                        label={configJSON.validTo}
                        variant="standard"
                        placeholder={configJSON.selectDate}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          min: configJSON.minDate,
                          max: configJSON.maxDate,
                        }}
                        defaultValue={this.formatDate(
                          this.state.valid_to,
                          configJSON.formatWeb
                        )}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({
                            valid_to: this.toDateISOString(event.target.value),
                          });
                        }}
                      />
                    </FormControl>

                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.minCardValueTestId}
                        type="number"
                        required
                        label={configJSON.minCartValue}
                        variant="standard"
                        defaultValue={this.state.min_cart_value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({ min_cart_value: event.target.value });
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>

                    <FormControl margin={"normal"}>
                      <TextField
                        data-test-id={configJSON.maxCardValueTestId}
                        type="number"
                        required
                        label={configJSON.maxCartValue}
                        variant="standard"
                        defaultValue={this.state.max_cart_value}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.update({ max_cart_value: event.target.value });
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </FormGroup>

                  <Divider
                    orientation="horizontal"
                    flexItem
                    style={{ margin: "1rem 0" }}
                  />

                  <Button
                    data-test-id={configJSON.createButtonTestId}
                    variant="contained"
                    color="primary"
                    disabled={this.state.isRefreshing}
                    onClick={() => this.createCoupon()}
                  >
                    {configJSON.create}
                  </Button>
                </>
              </Box>
            )}
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "1rem 0",
  },
  formGroupContainer: {
    margin: "2rem 0",
  },
  marginTop: {
    margin: "1rem 0 0",
  },
};
// Customizable Area End
