import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import CouponcodetableController, {
  configJSON,
  Props,
} from "./CouponcodetableController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import "@mui/styles";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class Couponcodetable extends CouponcodetableController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <Box sx={styles.flexRow}>
              <Button
                data-test-id={configJSON.generateNewItemTestId}
                variant="contained"
                onClick={() => this.navigateToCodeGenerator()}
              >
                {configJSON.generateNew}
              </Button>
            </Box>
            {this.state.isRefreshing ? (
              <Box
                width={"100%"}
                sx={{ ...styles.flexRow, ...styles.marginTop }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <List component="div">
                {this.state.data.map((value) => (
                  <ListItem
                    key={value.id}
                    data-test-id={configJSON.listItemTestId}
                    button
                    onClick={() => this.setSelected(value.attributes)}
                    divider
                  >
                    <ListItemText
                      inset
                      primary={configJSON.titleLabel}
                      secondary={value.attributes.title}
                    ></ListItemText>
                    <ListItemText
                      inset
                      primary={configJSON.code}
                      secondary={value.attributes.code}
                    ></ListItemText>
                    <ListItemText
                      inset
                      style={{
                        textTransform: "capitalize",
                      }}
                      primary={configJSON.discountType}
                      secondary={value.attributes.discount_type}
                    ></ListItemText>
                    <ListItemText
                      inset
                      primary={configJSON.description}
                      secondary={value.attributes.description}
                    ></ListItemText>
                  </ListItem>
                ))}
              </List>
            )}

            <Dialog
              fullWidth
              maxWidth={"sm"}
              open={!!this.state.selected}
              onClose={() => this.closeDialog()}
            >
              <DialogTitle>{this.state.selected?.title}</DialogTitle>
              {this.state.selected && (
                <DialogContent dividers>
                  <List disablePadding>
                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.titleLabel}
                        secondary={this.state.selected.title}
                      ></ListItemText>
                    </ListItem>
                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.discountType}
                        secondary={this.state.selected.discount_type}
                      ></ListItemText>
                    </ListItem>
                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.description}
                        secondary={this.state.selected.description}
                      ></ListItemText>
                    </ListItem>

                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.code}
                        secondary={this.state.selected.code}
                      ></ListItemText>
                    </ListItem>
                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.discount}
                        secondary={this.state.selected.discount}
                      ></ListItemText>
                    </ListItem>
                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.validFrom}
                        secondary={this.formatFromDateString(
                          this.state.selected.valid_from
                        )}
                      ></ListItemText>
                    </ListItem>

                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.validTo}
                        secondary={this.formatFromDateString(
                          this.state.selected.valid_to
                        )}
                      ></ListItemText>
                    </ListItem>
                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.minCartValue}
                        secondary={this.state.selected.min_cart_value}
                      ></ListItemText>
                    </ListItem>
                    <ListItem disableGutters style={styles.noPaddingY}>
                      <ListItemText
                        primary={configJSON.maxCartValue}
                        secondary={this.state.selected.max_cart_value}
                      ></ListItemText>
                    </ListItem>
                  </List>
                </DialogContent>
              )}
              <DialogActions>
                <Button
                  data-test-id={configJSON.deleteTestId}
                  onClick={() =>
                    this.state.selected &&
                    this.deleteRecord(this.state.selected.id)
                  }
                  color="secondary"
                >
                  {configJSON.delete}
                </Button>
                <Button
                  data-test-id={configJSON.closeTestId}
                  onClick={() => this.closeDialog()}
                  color="primary"
                >
                  {configJSON.close}
                </Button>
              </DialogActions>
            </Dialog>

            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "1rem 0",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1rem 0",
  },
  formGroupContainer: {
    margin: "2rem 0",
  },
  marginTop: {
    margin: "1rem 0 0",
  },
  noPaddingY: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

// Customizable Area End
