import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: any;
  title: string;
  description: string;
  code: string;
  discount: string;
  discount_type: string;
  discount_types: string[];
  valid_from: string;
  valid_to: string;
  min_cart_value: string;
  max_cart_value: string;
  token: string;
  isRefreshing: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
}

// Customizable Area Start
const DISCOUNT_TYPES = ["Flat", "Percentage"];
// Customizable Area End

export default class CouponcodegeneratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  addCouponApiCallId?: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      title: "",
      description: "",
      discount: "",
      discount_types: DISCOUNT_TYPES,
      discount_type: DISCOUNT_TYPES[0],
      code: "",
      id: 0,
      valid_from: moment().toDate().toISOString(),
      valid_to: moment().add(1, "day").toDate().toISOString(),
      min_cart_value: "",
      max_cart_value: "",
      token: "",
      isRefreshing: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
  }

  getToken = () => {
    this.send(new Message(getName(MessageEnum.SessionRequestMessage)));
  };

  receive = async (from: String, message: Message) => {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addCouponApiCallId &&
      this.addCouponApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ isRefreshing: false });

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || responseJson?.errors || !responseJson?.data) {
        this.parseApiCatchErrorResponse(
          errorResponse ?? configJSON.unexpectedError
        );
      } else {
        responseJson && responseJson.data && this.navigateToCouponCodeTable();
      }
    }
  };

  // Customizable Area Start
  createCoupon() {
    if (!this.checkRequiredFields()) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: this.state.token,
    };

    const attrs = {
      title: this.state.title,
      description: this.state.description,
      discount: this.state.discount,
      code: this.state.code,
      valid_from: this.state.valid_from,
      valid_to: this.state.valid_to,
      min_cart_value: this.state.min_cart_value,
      max_cart_value: this.state.max_cart_value,
      discount_type: this.state.discount_type.toLowerCase(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCouponApiCallId = requestMessage.messageId;

    const httpBody = {
      data: attrs,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createCouponAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isRefreshing: true });

    return true;
  }

  isStringNullOrBlank(value: string) {
    return value === null || value.length === 0;
  }

  checkRequiredFields() {
    const requiredFields: Array<keyof S> = [
      "title",
      "description",
      "discount",
      "code",
      "valid_from",
      "valid_to",
      "min_cart_value",
      "max_cart_value",
    ];

    return !requiredFields
      .map((value) => this.isStringNullOrBlank(this.state[value]))
      .find((value) => value);
  }

  navigateToCouponCodeTable = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Couponcodetable"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };

  toDateISOString(value: string, inputFormat?: string) {
    if (inputFormat) {
      value = moment(value, inputFormat).format(configJSON.defaultFormat);
    }
    return new Date(value).toISOString();
  }

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value }));
  }

  formatDate(value: string, format?: string) {
    return moment(value).format(format ?? configJSON.format);
  }

  // Customizable Area End
}
