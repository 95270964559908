Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeCouponGet = "GET";
exports.couponAPiEndPoint =
  "coupon_cg/coupon_code_generator";

exports.createCouponAPiMethod = "POST";
exports.deleteCouponAPiMethod = "DELETE";
exports.patchCouponAPiMethod = "PATCH";
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.couponApiContentType = "application/json";

exports.minDate = "01/01/20";
exports.maxDate = "01/01/25";
exports.formatMobile="DD-MM-YYYY";
exports.formatWeb="YYYY-MM-DD";
exports.format="DD-MM-YYYY";
exports.defaultFormat="YYYY-MM-DD";

exports.viewAll = "View All Coupons";
exports.titleLabel = "Title";
exports.discountType = "Discount Type";
exports.description = "Description";
exports.code = "Code";
exports.discount = "Discount";
exports.validFrom = "Valid From";
exports.validTo = "Valid To";
exports.confirm = "Confirm";
exports.ok = "OK";
exports.cancel = "Cancel";
exports.close = "Close";
exports.selectDate = "Select Date";
exports.minCartValue = "Min Cart Value";
exports.maxCartValue = "Max Cart Value";
exports.create = "Create";
exports.unexpectedError = "Unexpected Error Occurred";
exports.generateNew = "Generate New";
exports.delete = "Delete";
exports.view = "View";

exports.titleTestId = "titleTestId";
exports.descriptionTestId = "descriptionTestId";
exports.discountTypeTestId = "discountTypeTestId";
exports.codeTestId = "codeTestId";
exports.discountTestId = "discountTestId";
exports.validFromTestId = "validFromTestId";
exports.validToTestId = "validToTestId";
exports.minCardValueTestId = "minCardValueTestId";
exports.maxCardValueTestId = "maxCardValueTestId";
exports.createButtonTestId = "createButtonTestId";
exports.viewAllButtonTestId = "viewAllButtonTestId";
exports.progressTestId = "progressTestId";
exports.hideKeyboardTestId = "hideKeyboardTestId";
exports.listItemTestId = "listItemTestId";
exports.generateNewItemTestId = "generateNewItemTestId";
exports.deleteTestId = "deleteTestId";
exports.closeTestId = "closeTestId";


// Customizable Area End
